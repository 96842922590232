<template>
  <div class="page3">
    <div v-for="item in list" :key="item.id">
      <choose :set="item" v-on:choose="change" :id="'cate' + item.cate.id"></choose>
    </div>
    <van-button class="btn" round block type="info" @click="next">下一步</van-button>
  </div>
</template>

<script>
import Choose from './Choose.vue';

export default {
  name: 'Page3',
  components: { Choose },
  data() {
    return {
      list: [],
    };
  },
  created() {
    document.documentElement.scrollTop = 0;
    const set = JSON.parse(this.combo.set);
    const rt = [];
    Object.keys(set).forEach((key) => {
      rt.push({
        cate: this.$store.state.db.category.find((i) => i.id === parseInt(key, 10)),
        brands: set[key].map((i) => this.$store.state.db.brands.find((j) => j.id === i) || {}),
        defaultBrandId: set[key][0],
        selectBrandId: this.getOrderBrand(parseInt(key, 10))
          ? this.getOrderBrand(parseInt(key, 10))
          : set[key][0],
      });
    });

    // 按cateogry排序
    const tmp = [];
    for (let i = 0; i < this.category.length; i++) {
      const item = rt.find((it) => it.cate.id === this.category[i]);
      if (item) {
        tmp.push(item);
      }
    }

    this.list = tmp;
    this.makeOrder(tmp);
  },
  methods: {
    getOrderBrand(cateId) {
      const list = this.$store.state.order.list;
      if (list.length > 0) {
        const item = list.find((it) => it.cate.id === cateId);
        return item.brand.id;
      }
      return 0;
    },
    next() {
      this.$store.commit('setPage', 4);
    },
    isArray(ary) {
      return Array.isArray(ary);
    },
    change(id) {
      const domPre = document.getElementById(`cate${id}`);
      const idx = this.category.findIndex((it) => it === id);
      const dom = document.getElementById(`cate${this.category[idx + 1]}`);
      if (dom && domPre) {
        const rect = domPre.getBoundingClientRect();
        const body = document.documentElement;
        setTimeout(() => {
          body.scrollTo({
            top: dom.offsetTop - rect.height,
            behavior: 'smooth',
          });
        }, 200);
      }
    },
    makeOrder(val) {
      const rt = [];
      val.forEach((item) => {
        const brand = item.brands.find((it) => it.id === item.selectBrandId) || {};
        const defaultBrand = item.brands.find((it) => it.id === item.defaultBrandId) || {};
        rt.push({
          cate: item.cate,
          brand,
          defaultBrand,
        });
      });
      this.$store.commit('setOrder', { combo: this.combo, list: rt });
    },
  },
  computed: {
    combo() {
      return this.$store.state.db.combo.find((it) => it.id === this.$store.state.cate) || {};
    },
    category() {
      return this.$store.state.db.category.map((it) => it.id);
    },
  },
  watch: {
    list: {
      deep: true,
      handler(newVal) {
        this.makeOrder(newVal);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.page3 {
  position: relative;
  left: 100vw;
  padding: 0 20px;
  animation: fade-in 0.2s ease-in-out 0s 1 normal forwards;
  .btn {
    margin: 60px 0;
  }
}
</style>
