import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    db: {
      combo: [],
      brands: [],
      category: [],
    },
    cate: 1,
    page: 1,
    order: {
      no: 0,
      phone: '',
      username: '',
      combo: {},
      list: [],
      ext: [],
      info: {
        address: '',
        acreage: 90,
        room: 2,
        hall: 1,
        toilet: 1,
      },
    },
  },
  mutations: {
    setCombo(state, obj) {
      state.db = obj;
    },
    setCate(state, obj) {
      state.cate = obj;
      state.order.list = [];
      state.order.ext = [];
    },
    setPage(state, obj) {
      state.page = obj;
    },
    setUser(state, obj) {
      state.order.phone = obj.phone;
      state.order.username = obj.username;
    },
    setOrder(state, obj) {
      state.order.combo = obj.combo;
      state.order.list = obj.list;
    },
    setInfo(state, obj) {
      state.order.info = obj;
    },
    setExt(state, obj) {
      state.order.ext = obj;
    },
    setNo(state, obj) {
      state.order.no = obj;
    },
  },
  actions: {
    getCombo({ commit }) {
      api.get('/api/combo').then((rt) => {
        commit('setCombo', rt);
      });
    },
  },
  modules: {},
});
