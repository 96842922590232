<template>
  <div class="page6">
    <div class="wrap">
      <div class="webpage" id="webpage">
        <div class="company">
          <div class="logo">
            <img src="../assets/logo.jpeg" />
          </div>
          <div class="info">
            <div class="addr"><van-icon name="location" /> 嘉善县嘉善大道688号C座3楼</div>
            <div class="phone"><van-icon name="phone" />138 1693 3859</div>
          </div>
        </div>
        <div class="user">
          <div class="line">
            <van-icon name="vip-card" />
            <span> {{ order.no }}</span>
          </div>
          <div class="line">
            <van-icon name="friends" />
            <span> {{ order.username }} {{ order.phone }}</span>
          </div>
          <div class="line">
            <van-icon name="gift" />
            <span> {{ order.combo.name }}</span>
          </div>
          <div class="line">
            <van-icon name="setting" />
            <span>
              {{ info.acreage }}平米/{{ info.room }}室/{{ info.hall }}厅/{{ info.toilet }}卫
              {{ info.type }}
            </span>
          </div>
          <div class="line">
            <van-icon name="location" />
            <span> {{ info.address }} {{ info.road }} </span>
          </div>
        </div>
        <div class="order">
          <div class="label">配置清单</div>
          <div class="item" v-for="(it, idx) in order.list" :key="idx">
            <div class="cate">
              {{ it.cate.name }}
            </div>
            <div class="brand">
              <div class="left">
                {{ it.brand.name }}{{ it.brand.series ? '-' + it.brand.series : '' }}
              </div>
              <span class="right" :class="{ red: getTag(it) > 0 }">
                {{ getTag(it) === 0 ? '标配' : getTag(it) > 0 ? '+' + getTag(it) : getTag(it) }}
              </span>
            </div>
          </div>
        </div>
        <div class="ext" v-if="order.ext.length > 0">
          <div class="label">附加产品及增值服务</div>
          <div class="wrap">
            <div class="item" v-for="(it, idx) in order.ext" :key="idx">
              {{ it.name }}{{ it.series ? '-' + it.series : '' }}
              <span class="right"> +{{ it.price }}</span>
            </div>
          </div>
        </div>
        <div class="total">
          <img src="../assets/qr.png" />
          <span>
            <div>总价：{{ totalMoney }} 元</div>
            <div class="tips">凭单到店将获随机礼品一份</div>
          </span>
        </div>
      </div>
      <img class="canvas" :src="imgData" />
    </div>
    <div class="btn">长按清单保存至本机</div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
  name: 'Page6',
  data() {
    return {
      imgData:
        'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==',
    };
  },
  methods: {
    getTag(it) {
      return it.brand.price - it.defaultBrand.price;
    },
  },
  computed: {
    order() {
      return this.$store.state.order;
    },
    info() {
      return this.order.info;
    },
    totalMoney() {
      let tmp = this.order.combo.total;
      // 面积
      tmp += this.info.acreage * this.order.combo.meter;
      // 浴室
      tmp += this.info.toilet * this.order.combo.bathroom;
      // 附加
      const list = this.order.list;
      list.forEach((it) => {
        tmp += it.brand.price - it.defaultBrand.price;
      });
      // 折扣
      tmp = (tmp * this.order.combo.discount) / 100;
      // 附加
      const ext = this.order.ext;
      ext.forEach((it) => {
        tmp += it.price;
      });

      return tmp;
    },
  },
  mounted() {
    html2canvas(document.querySelector('#webpage')).then((canvas) => {
      this.imgData = canvas.toDataURL('image/png');
    });
  },
};
</script>

<style lang="less" scoped>
.page6 {
  position: relative;
  padding: 0 20px;
  .wrap {
    position: relative;
    .webpage {
      padding: 40px 20px;
      .company {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #eee;
        margin-bottom: 20px;
        .logo {
          line-height: 80px;
          img {
            height: 60px;
            vertical-align: middle;
          }
          .phone {
            float: right;
          }
        }
        .info {
          font-size: 28px;
          line-height: 40px;
          margin: 10px 0;
          text-align: right;
          .addr {
            float: right;
          }
        }
      }
      .user {
        background: #f5f5f5;
        padding: 10px 20px;
        border-radius: 10px;
      }
      .order {
        border-top: solid 1px #eee;
        margin-top: 20px;
        .label {
          font-size: 36px;
          padding: 20px 0;
          font-weight: bold;
        }
        .item {
          .cate {
            color: #666;
            font-weight: 500;
            background: #f5f5f5;
            border-radius: 8px;
            padding: 8px 20px;
          }
          .brand {
            color: #000;
            display: flex;
            justify-content: space-between;
            padding: 12px 20px;
            .left {
              color: #333;
              text-align: left;
              padding-right: 40px;
            }
            .right {
              flex: none;
              color: green;
              &.red {
                color: red;
              }
            }
          }
        }
      }
      .ext {
        border-top: solid 1px #eee;
        margin-top: 20px;
        .label {
          font-size: 36px;
          padding: 20px 0;
          font-weight: bold;
        }
        .wrap {
          background: #f5f5f5;
          border-radius: 8px;
          .item {
            padding: 2px 20px;
            line-height: 56px;
            .right {
              float: right;
              color: red;
            }
          }
        }
      }
      .total {
        border-top: solid 1px #eee;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        font-size: 56px;
        font-weight: bold;
        text-align: right;
        img {
          width: 120px;
        }
        .tips {
          font-size: 24px;
          color: #999;
          margin-top: 10px;
        }
      }
    }
    .canvas {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .btn {
    text-align: center;
    padding: 20px;
    background: #1989fa;
    color: #fff;
    border-radius: 8px;
    margin-bottom: 40px;
  }
}
</style>
