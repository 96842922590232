<template>
  <div class="page5">
    <div class="msg">请输入联系方式获取清单与报价</div>
    <van-form @submit="submit">
      <van-field
        v-model="form.phone"
        name="手机号码"
        label="手机号码"
        type="tel"
        placeholder="请输入手机号码"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="form.username"
        name="密码"
        label="称呼"
        placeholder="请输入您的称呼"
        :rules="[{ required: true }]"
      />
      <div class="btn">
        <van-button round block type="info" native-type="submit">下一步</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'Page5',
  data() {
    return {
      form: {
        phone: '',
        username: '',
      },
    };
  },
  methods: {
    submit() {
      this.$store.commit('setUser', this.form);
      api.post('/api/order', this.$store.state.order).then((rt) => {
        this.$store.commit('setNo', rt.id);
        this.$store.commit('setPage', 6);
      });
    },
  },
  computed: {
    order() {
      return this.$store.state.order;
    },
  },
};
</script>

<style lang="less" scoped>
.page5 {
  position: relative;
  left: 100vw;
  animation: fade-in 0.2s ease-in-out 0s 1 normal forwards;
  padding: 100px 20px;
  .msg {
    margin: 10px 30px 60px;
    font-weight: bold;
    font-size: 40px;
  }
  .btn {
    margin: 60px 20px;
  }
}
</style>
