import Vue from 'vue';
import Vant from 'vant';
import smoothscroll from 'smoothscroll-polyfill';
import 'amfe-flexible/index';
import App from './App.vue';
import store from './store';
import 'vant/lib/index.css';

smoothscroll.polyfill();
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
