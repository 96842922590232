<template>
  <div class="choose">
    <div class="title">{{ set.cate.name }}</div>
    <div class="desc" v-if="set.cate.desc">{{ set.cate.desc }}</div>
    <template v-if="type === 1">
      <div
        class="item"
        :class="{ selected: set.selectBrandId === item.id }"
        v-for="item in set.brands"
        @click="choose(item)"
        :key="item.id"
      >
        <div class="txt">
          <div class="brand" v-html="item.series ? item.name + '-' + item.series : item.name"></div>
          <div class="price" v-if="getPrice(item)">
            [{{ getPrice(item) > 0 ? '加' : '减' }} RMB {{ toThousands(Math.abs(getPrice(item))) }}]
          </div>
          <div class="price" v-else>[标配]</div>
        </div>
      </div>
    </template>
    <template v-if="type === 2">
      <div class="wrap2">
        <div
          class="item"
          :class="{ selected: set.selectBrandId === item.id }"
          v-for="item in set.brands"
          @click="choose(item)"
          :key="item.id"
        >
          <div class="txt">
            <div
              class="brand"
              v-html="item.series ? item.name + '-' + item.series : item.name"
            ></div>
            <div class="price" v-if="getPrice(item)">
              [{{ getPrice(item) > 0 ? '加' : '减' }} RMB
              {{ toThousands(Math.abs(getPrice(item))) }}]
            </div>
            <div class="price" v-else>[标配]</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="type === 5">
      <div class="wrap5">
        <div class="item selected" @click="show = true">
          <div class="txt">
            <div
              class="brand"
              v-html="
                selectBrand.series ? selectBrand.name + '-' + selectBrand.series : selectBrand.name
              "
            ></div>
            <div class="price" v-if="getPrice(selectBrand)">
              [{{ getPrice(selectBrand) > 0 ? '加' : '减' }} RMB
              {{ toThousands(Math.abs(getPrice(selectBrand))) }}]
            </div>
            <div class="price" v-else>[标配]</div>
          </div>
          <van-icon name="arrow-down" />
        </div>
        <van-popup v-model="show" round position="bottom">
          <van-picker
            :title="set.cate.name"
            show-toolbar
            :columns="options"
            @cancel="onCancel"
            @confirm="onConfirm"
          >
          </van-picker>
        </van-popup>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Choose',
  props: ['set'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    choose(item) {
      if (this.set.selectBrandId !== item.id) {
        this.$emit('choose', this.set.cate.id);
      }
      this.set.selectBrandId = item.id;
    },
    getPrice(item) {
      const select = this.set.brands.find((it) => it.id === this.set.defaultBrandId) || {};
      return item.price - select.price;
    },
    toThousands(num) {
      const result = [];
      let counter = 0;
      num = (num || 0).toString().split('');
      for (let i = num.length - 1; i >= 0; i -= 1) {
        counter += 1;
        result.unshift(num[i]);
        if (!(counter % 3) && i !== 0) {
          result.unshift(',');
        }
      }
      return result.join('');
    },
    onConfirm(value, index) {
      const brand = this.set.brands[index];
      this.set.selectBrandId = brand.id;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
  },
  computed: {
    type() {
      let rt = 1;
      const brands = this.set.brands;
      if (
        brands.length === 2 &&
        `${brands[0].name}-${brands[0].series}`.length < 8 &&
        `${brands[1].name}-${brands[1].series}`.length < 8
      ) {
        rt = 2;
      } else if (brands.length > 4) {
        rt = 5;
      }
      return rt;
    },
    options() {
      return this.set.brands.map((it) => {
        let str = it.series ? `${it.name}-${it.series}` : it.name;
        const no = this.getPrice(it);
        if (no > 0) {
          str += ` [加 ${no} RMB]`;
        } else if (no < 0) {
          str += ` [减 ${Math.abs(no)} RMB]`;
        } else {
          str += ' [标配]';
        }
        return str;
      });
    },
    selectBrand() {
      const brand = this.set.brands.find((it) => it.id === this.set.selectBrandId);
      return brand || {};
    },
  },
};
</script>

<style lang="less" scoped>
.choose {
  border-bottom: solid 1px #d6d6d6;
  padding: 20px 0;
  .title {
    font-size: 32px;
    line-height: 40px;
    padding: 10px;
    font-weight: bold;
  }
  .desc {
    font-size: 24px;
    padding: 6px 10px;
    color: #666;
    white-space: pre-wrap;
    line-height: 36px;
  }
  .item {
    border: solid 1px #d6d6d6;
    padding: 24px 0;
    margin: 20px 0;
    border-radius: 12px;
    &:focus {
      outline: 3px solid #1989fa;
      outline-offset: -1px;
    }
    .txt {
      padding: 0 32px;
      .brand {
        font-size: 32px;
        line-height: 40px;
        color: #000;
        margin-bottom: 5px;
        font-weight: bold;
      }
      .msg {
        font-size: 24px;
        line-height: 40px;
        color: #7a869a;
        margin-top: 4px;
      }
    }
    .price {
      color: #7a869a;
      font-size: 28px;
    }
  }
  .wrap2 {
    display: flex;
    .item {
      margin: 10px;
      width: 95%;
    }
  }
  .wrap5 {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .van-icon {
        margin: 0 20px;
      }
    }
  }
  .selected {
    border: 4px solid #1989fa;
  }
}
</style>
