<template>
  <div class="page2">
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th v-for="item in combo" :key="item.id" width="23%">
            <div>{{ item.name }}</div>
            <van-button class="btn" type="info" @click="setCate(item.id)">选择</van-button>
            <div class="start">{{ item.start }} 起</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in list" :key="item.category">
          <td class="col1">
            <div style="width: 30px">{{ item.cateName }}</div>
          </td>
          <td v-for="(it, i) in combo" :key="i">
            <cell
              v-if="item['combo' + it.id].brands[0]"
              :txt="item['combo' + it.id].brands[0].name"
              :optional="
                item['combo' + it.id].brands[0].id !== item['combo' + it.id].defaultBrandId
              "
            ></cell>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Cell from './Cell.vue';

export default {
  name: 'Page2',
  components: { Cell },
  methods: {
    setCate(id) {
      this.$store.commit('setCate', id);
      this.$store.commit('setPage', 3);
    },
  },
  computed: {
    db() {
      return this.$store.state.db;
    },
    combo() {
      return this.db.combo;
    },
    list() {
      const line = [];
      const fields = this.db.combo.map((item) => `combo${item.id}`);
      this.db.category.forEach((it) => {
        const tmp = {
          cateId: it.id,
          cateName: it.name,
        };
        for (let i = 0; i < fields.length; i += 1) {
          tmp[fields[i]] = {
            brands: [],
            defaultBrandId: 0,
          };
        }
        line.push(tmp);
      });
      this.db.combo.forEach((i) => {
        const set = JSON.parse(i.set);
        Object.keys(set).forEach((key) => {
          const targetLine = line.find((it) => it.cateId === parseInt(key, 10));
          targetLine[`combo${i.id}`].brands = set[key].map(
            (id) => this.db.brands.find((brand) => brand.id === id) || {},
          );
          targetLine[`combo${i.id}`].defaultBrandId = set[key][0];
        });
      });
      return line;
    },
  },
};
</script>

<style lang="less" scoped>
.page2 {
  position: relative;
  margin-bottom: 60px;
  padding: 0 10px;
  left: 100vw;
  animation: fade-in 0.2s ease-in-out 0s 1 normal forwards;
  table,
  th,
  td {
    border: 2px solid #ebeef5;
  }
  .table {
    margin-bottom: 20px;
    border-collapse: collapse;
    thead {
      position: sticky;
      inset-block-start: 0;
    }
    .btn {
      color: #fff;
      font-size: 28px;
      line-height: 52px;
      border-radius: 8px;
      text-align: center;
      height: 52px;
      margin: 20px 4px 10px;
    }
    tbody {
      overflow-y: auto;
      height: calc(100vh - 40px);
    }
    th {
      text-align: center;
      background: #f5f7fa;
      padding: 10px;
      .start {
        font-size: 26px;
        font-weight: normal;
      }
    }
    td {
      font-size: 24px;
      text-align: center;
      vertical-align: middle;
      padding: 5px;
      &.col1 {
        font-weight: bold;
        padding: 10px;
        background: #f5f7fa;
      }
      .cell {
        margin: 4px;
        &:nth-child(2) {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
