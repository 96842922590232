<template>
  <div class="page4">
    <vue-masonry-wall :items="brands" :options="options">
      <template v-slot:default="{ item }">
        <div
          class="border"
          @click="choose(item)"
          :class="{ selected: chooseList.map((it) => it.id).includes(item.id) }"
        >
          <img class="img" :src="item.pics" />
          <div class="txt">
            <div class="word">{{ item.name }} - {{ item.series }}</div>
            <div class="price">{{ item.price }} 元</div>
            <div class="intro">{{ item.intro }}</div>
          </div>
        </div>
      </template>
    </vue-masonry-wall>
    <van-button class="btn" round block type="info" @click="next">下一步</van-button>
  </div>
</template>

<script>
import VueMasonryWall from 'vue-masonry-wall';

export default {
  name: 'Page4',
  components: { VueMasonryWall },
  data() {
    return {
      chooseList: [],
      options: {
        width: 200,
        padding: {
          2: 6,
          default: 8,
        },
      },
    };
  },
  created() {
    document.documentElement.scrollTo({
      top: 0,
    });
  },
  methods: {
    style(url) {
      return {
        'background-image': `url(${url})`,
      };
    },
    choose(it) {
      const idx = this.chooseList.findIndex((item) => item.id === it.id);
      if (idx > -1) {
        this.chooseList.splice(idx, 1);
      } else {
        this.chooseList.push(it);
      }
    },
    next() {
      this.$store.commit('setExt', this.chooseList);
      this.$store.commit('setPage', 5);
    },
  },
  computed: {
    brands() {
      return this.$store.state.db.brands.filter((it) => it.category_id === 1);
    },
  },
};
</script>

<style lang="less" scoped>
.page4 {
  position: relative;
  left: 100vw;
  padding: 0 20px;
  animation: fade-in 0.2s ease-in-out 0s 1 normal forwards;
  .border {
    border: solid 1px #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    line-height: 40px;
    .img {
      width: 100%;
      height: 100%;
    }
    .txt {
      width: 100%;
      text-align: center;
      padding: 20px 0;
      .word {
        width: 100%;
        text-align: center;
        font-weight: bold;
      }
      .price {
        font-size: 28px;
      }
      .intro {
        font-size: 24px;
        color: #666;
      }
    }
  }
  .selected {
    border: 4px solid #1989fa;
  }
  /deep/ .masonry-bottom {
    min-height: 0;
  }
  .btn {
    clear: both;
    margin: 60px 0;
  }
}
</style>
