<template>
  <div class="page0">
    <img src="../assets/index.jpeg" />
    <div class="btn" @click="next"></div>
  </div>
</template>

<script>
export default {
  name: 'Page0',
  data() {
    return {};
  },
  methods: {
    next() {
      this.$store.commit('setPage', 1);
    },
  },
};
</script>

<style lang="less" scoped>
.page0 {
  margin: 0;
  padding: 0;
  position: relative;
  img {
    width: 100%;
  }
  .btn {
    width: 480px;
    height: 130px;
    position: absolute;
    bottom: 170px;
    left: 138px;
    cursor: pointer;
  }
}
</style>
