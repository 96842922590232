<template>
  <div class="cell" :class="{ optional: optional }" v-html="html"></div>
</template>

<script>
export default {
  name: 'Cell',
  props: ['txt', 'optional'],
  computed: {
    html() {
      if (!this.txt) return '';
      let str = '';
      const tmp = this.txt.split('<br>');
      tmp.forEach((line) => {
        str += `<div class="line">${line}</div>`;
      });
      return str;
    },
  },
};
</script>

<style lang="less">
.cell {
  padding: 0;
  // background: #f5f7fa;
  color: #172b4d;
  border-radius: 8px;
  .line {
    padding: 2px 10px;
  }
}
.optional {
  background: #06c;
  color: #fff;
  border-radius: 8px;
}
</style>
