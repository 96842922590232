import axios from 'axios';

const service = axios.create({
  baseURL: '/',
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
  responseType: 'json',
});

service.interceptors.response.use(
  (response) => {
    const body = response.data || {};
    if (body.code === 0) {
      return Promise.resolve(body.data);
    }
    return Promise.reject(body);
  },
  (error) => Promise.reject(error),
);

export default service;
