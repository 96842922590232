<template>
  <div class="top-bar" v-if="page > 0">
    <div class="back" v-if="page > 1" @click="back"><van-icon name="arrow-left" /></div>
    <div class="txt">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  methods: {
    back() {
      this.$store.commit('setPage', this.$store.state.page - 1);
    },
  },
  computed: {
    combo() {
      return this.$store.state.db.combo.find((it) => it.id === this.$store.state.cate);
    },
    title() {
      let rt = '';
      switch (this.page) {
        case 1:
          rt = '欢迎光临';
          break;
        case 2:
          rt = '请选择一个套餐';
          break;
        case 3:
          rt = this.combo.name;
          break;
        case 4:
          rt = '附加产品及增值服务';
          break;
        case 5:
          rt = '输入联系方式';
          break;
        case 6:
          rt = '清单下载';
          break;
        default:
      }
      return rt;
    },
    page() {
      return this.$store.state.page;
    },
  },
};
</script>

<style lang="less" scoped>
.top-bar {
  text-align: left;
  padding: 10px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  position: relative;
  font-size: 34px;
  .back {
    position: absolute;
    cursor: pointer;
  }
  .txt {
    font-weight: bold;
  }
}
</style>
