<template>
  <div id="app">
    <top-bar></top-bar>
    <div class="page">
      <page-0 v-show="page === 0"></page-0>
      <page-1 v-show="page === 1"></page-1>
      <page-2 v-show="page === 2"></page-2>
      <page-3 v-if="page === 3"></page-3>
      <page-4 v-if="page === 4"></page-4>
      <page-5 v-show="page === 5"></page-5>
      <page-6 v-if="page === 6"></page-6>
    </div>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import Page0 from './components/Page0.vue';
import Page1 from './components/Page1.vue';
import Page2 from './components/Page2.vue';
import Page3 from './components/Page3.vue';
import Page4 from './components/Page4.vue';
import Page5 from './components/Page5.vue';
import Page6 from './components/Page6.vue';

export default {
  name: 'App',
  components: {
    TopBar,
    Page0,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
  },
  created() {
    this.$store.dispatch('getCombo');
  },
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: SF Pro SC, SF Pro Text, SF Pro Icons, AOS Icons, PingFang SC, Helvetica Neue,
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  font-size: 32px;
}

@keyframes fade-in {
  form {
    left: 100vw;
  }
  to {
    left: 0;
  }
}
</style>
