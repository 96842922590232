<template>
  <div class="page1">
    <div class="logo">
      <img src="../assets/logo.jpeg" />
    </div>
    <van-form @submit="next">
      <van-field
        v-model="form.address"
        is-link
        readonly
        label="区域"
        placeholder="请选择所在的区域"
        @click="show = true"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="area"
          title="请选择所在区域"
          :options="options"
          @close="show = false"
          @finish="finish"
        />
      </van-popup>
      <van-field v-model="form.road" label="街道" placeholder="选填，请输入具体地址" />
      <van-field
        readonly
        clickable
        name="picker"
        :value="form.type"
        label="房型"
        placeholder="点击选择房型"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="types"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field v-model="form.acreage" type="digit" label="面积">
        <template #button> 平米 </template>
      </van-field>
      <van-row type="flex" justify="center">
        <van-col span="6">
          <van-stepper :show-minus="false" input-width="42" v-model="form.room" min="1" max="5" />
        </van-col>
        <van-col span="2">室</van-col>
        <van-col span="6">
          <van-stepper :show-minus="false" input-width="42" v-model="form.hall" min="1" max="5" />
        </van-col>
        <van-col span="2">厅</van-col>
        <van-col span="6">
          <van-stepper :show-minus="false" input-width="42" v-model="form.toilet" min="1" max="5" />
        </van-col>
        <van-col span="2">卫</van-col>
      </van-row>
      <div class="btn">
        <van-button
          round
          block
          :disabled="!form.acreage || !form.address"
          type="info"
          native-type="submit"
          >下一步</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: 'Page1',
  data() {
    return {
      options: [
        {
          text: '嘉兴市',
          value: '嘉兴市',
          children: [
            { text: '嘉兴市区', value: '嘉兴市区' },
            { text: '嘉善县', value: '嘉善县' },
            { text: '平湖', value: '平湖' },
          ],
        },
        {
          text: '上海市',
          value: '上海市',
          children: [
            { text: '黄浦区', value: '黄浦区' },
            { text: '徐汇区', value: '徐汇区' },
            { text: '长宁区', value: '长宁区' },
            { text: '静安区', value: '静安区' },
            { text: '普陀区', value: '普陀区' },
            { text: '虹口区', value: '虹口区' },
            { text: '杨浦区', value: '杨浦区' },
            { text: '闵行区', value: '闵行区' },
            { text: '宝山区', value: '宝山区' },
            { text: '金山区', value: '金山区' },
            { text: '嘉定区', value: '嘉定区' },
            { text: '青浦区', value: '青浦区' },
            { text: '松江区', value: '松江区' },
            { text: '奉贤区', value: '奉贤区' },
            { text: '崇明区', value: '崇明区' },
            { text: '浦东新区', value: '浦东新区' },
          ],
        },
      ],
      types: [
        {
          text: '平层',
          value: '平层',
        },
        {
          text: '联排',
          value: '联排',
        },
        {
          text: '别墅',
          value: '别墅',
        },
        {
          text: '叠层',
          value: '叠层',
        },
        {
          text: '自建房',
          value: '自建房',
        },
      ],
      area: '',
      show: false,
      showPicker: false,
      form: {
        address: '',
        road: '',
        type: '',
        acreage: 90,
        room: 2,
        hall: 1,
        toilet: 1,
      },
    };
  },
  methods: {
    next() {
      this.$store.commit('setInfo', this.form);
      this.$store.commit('setPage', 2);
    },
    finish({ selectedOptions }) {
      this.show = false;
      this.form.address = selectedOptions.map((option) => option.text).join(' ');
    },
    onConfirm(value) {
      this.showPicker = false;
      this.form.type = value.text;
    },
  },
};
</script>

<style lang="less" scoped>
.page1 {
  position: relative;
  padding: 0 20px;
  .logo {
    text-align: center;
    padding: 120px 0 80px;
    img {
      height: 70px;
    }
  }
  .van-form {
    .van-row {
      text-align: center;
      padding: 30px;
      line-height: 56px;
      margin-bottom: 10px;
    }
  }
  .btn {
    margin: 20px 40px;
  }
}
</style>
